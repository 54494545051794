import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto key verify`}</strong>{` -- verify a signed message`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto key verify [<file>] --key=<key-file> --signature=<base64>
[--alg=<algorithm>] [--pss]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto key verify`}</strong>{` verifies the signature of a file or a message.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file`}</inlineCode>{`
File to verify.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the public key.`}</p>
    <p><strong parentName="p">{`--signature`}</strong>{`=`}<inlineCode parentName="p">{`base64`}</inlineCode>{`, `}<strong parentName="p">{`--sig`}</strong>{`=`}<inlineCode parentName="p">{`base64`}</inlineCode>{`
The `}<inlineCode parentName="p">{`base64`}</inlineCode>{` version of the signature.`}</p>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The hash algorithm to use on RSA PKCS #1 1.5 and RSA-PSS signatures.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha1`}</strong>{` (or sha): SHA-1 produces a 160-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha224`}</strong>{`: SHA-224 produces a 224-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha256`}</strong>{` (default): SHA-256 produces a 256-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha384`}</strong>{`: SHA-384 produces a 384-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512`}</strong>{`: SHA-512 produces a 512-bit hash value`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-224`}</strong>{`: SHA-512/224 uses SHA-512 and truncates the output to 224 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sha512-256`}</strong>{`: SHA-512/256 uses SHA-512 and truncates the output to 256 bits`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`md5`}</strong>{`: MD5 produces a 128-bit hash value`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--pss`}</strong>{`
Verify using the RSA-PSS signature scheme.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Verify a file with its signature:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`s step crypto key verify --key pub.key --sig "base64...=" file.txt
true
`}</code></pre>
    <p>{`Verify a file using the PKCS #1 v1.5:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key verify --key rsa.pub --sig "base64...=" file.txt
`}</code></pre>
    <p>{`Verify a file using the PKCS #1 v1.5 and SHA512:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key verify --key rsa.pub --alg sha512 --sig "base64...=" file.txt
`}</code></pre>
    <p>{`Verify a file using the RSA-PSS scheme:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key verify --key rsa.pub --pss --sig "base64...=" file.txt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      